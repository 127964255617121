#footer {
 .bg-dark {
  .nav-link {
   color: $white;
   &:hover {
    color: $primary;
   }
  }
 }
 .logo {
  --bs-dark: #fff;
  --bs-dark-rgb: 255,255,255;
 }
}
#footerSiteNav {
 font-size: 12px;
}
#privyNav {
 .nav-item {
  &:last-child {
   .nav-link {
    border-right: 1px solid $light;
   }
  }
 }
 .nav-link {
  color: $gray-400;
  padding: 0 $spacer/2;
  border-left: 1px solid $light;
 }
}