#header {
 position: relative;
 z-index: 1;
 .logoContainer, .navContainer {
  justify-content: space-between;
 }
 .logoContainer {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  @include media-breakpoint-up(md) {
   max-width: 50%;
  }
  @include media-breakpoint-up(lg) {
   justify-content: flex-start;
   display: block;
  }
  @include media-breakpoint-up(xl) {
   max-width: 480px;
  }
  @include media-breakpoint-up(xxl) {
   max-width: 500px;
  }
 }
 .navContainer {
  flex: 1 1 auto;
  display: flex;
  flex-wrap: wrap;
  @include media-breakpoint-down(sm) {
   .nav {
    justify-content: center;
   }
  }
  @include media-breakpoint-between(sm, xl) {
   .nav {
    justify-content: flex-end;
   }
  }
  @include media-breakpoint-only(md) {
   flex-direction: column;
  }
  @include media-breakpoint-up(md) {
   flex-wrap: nowrap;
   [class^=fa-] {
    font-size: calc(1.275rem + 0.05vw);
   }
   #auxMenu {
    [class^=fa-] {
     bottom: -5px;
     position: relative;
     margin-right: 5px;
     font-size: calc(1.275rem + 0.6vw);
    }
   }
  }
  @include media-breakpoint-down(lg) {
   .nav-link {
    padding: .25rem .5rem;
   }
  }
  @include media-breakpoint-only(lg) {
   display: block;
  }
 }
}
#auxMenu {
 .nav-link {
  span {
   font-weight: 600;
   font-size: 90%;
  }
 }
}
#websiteMainNav {
 @include media-breakpoint-between(md, xl) {
  .navbar-nav {
   &, > .nav-link, .nav-item {
    flex: 1 1 auto;
   }
   > .nav-link, .nav-item {
    text-align: center;
   }
  }
 }
 .nav-link {
  color: $white;
  @include media-breakpoint-down(xl) {
   letter-spacing: 0!important;
   font-size: 95%;
  }
  &.active, &:active, &:hover, &.show {
   color: tint-color($primary, 46%); // #77bd95
  }
  &:hover, &.show {
   background-color: rgba($white, .1);
  }
  @include media-breakpoint-up(lg) {
   text-transform: uppercase;
  }
  @include media-breakpoint-up(xl) {
   padding-left: $spacer*1.5;
   padding-right: $spacer*1.5;
  }
 }
 .dropdown {
  &-menu {
   right: 0;
   left: 0;
   border-radius: 0;
   border-width: 0;
   box-shadow: $box-shadow;
   margin-top: 0!important;
   min-width: unset;
  }
  &-item {
   white-space: initial;
  }
 }
}