$white: #fff;
$gray-100: #F8F8F8;
$gray-200: #E1E1E1;
$gray-300: #D3D3D3;
$gray-400: #C4C4C4;
$gray-500: #B5B5B5;
$gray-600: #929292;
$gray-700: #707070;
$gray-800: #4D4D4D;
$gray-900: #2A2A2A;
$black: #000;

$blue: #032ec1;
$indigo: #3918a8;
$purple: #6f028f;
$pink: #c1038d;
$red: #c1032e;
$orange: #daa903;
$yellow: #a0da03;
$green: #77bd95;
$teal: #028f6f;
$cyan: #03a0da;

$primary: $teal;

$light: $gray-100;
$body-color: mix($gray-600, $gray-700); // #838383
$link-color: $green; // #77bd95