@import 'design/header';
@import 'design/footer';

a {
 [class^=fa-] {
  color: $primary;
 }
}
h1, .fs-1, h2, .fs-2, h3, .fs-3 {
 font-weight: $font-weight-bold; // should be 600, default headings weight is 400.
}

/* make font-size classes look like headings */
[class^=fs-]:not([class^=fa-]):not([class*=display-]) {
 font-family: $headings-font-family; // should be Nunito
}
.shiftUp {
 margin-top: -3rem;
 > section:first-of-type {
  padding-top: 3rem!important;
 }
}
[class*=display-] {
 font-family: serif;
 font-weight: initial;
}
.spaced {letter-spacing: 2px;}
.fadeFromLeft {
 position: relative;
 &:before {
  content: '';
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(to right, rgba($black, .75), transparent);
  z-index: -1;
  position: absolute;
 }
}

.bg-dark {
 .bg-dark {
  background-color: #171717!important;
 }
}
.bg-black-70 {
 background-color: rgba($black, .7);
}
.styled {
 position: relative;
 line-height: 2;
 &:after {
  content: '';
  width: 4rem;
  left: 0;
  bottom: 0;
  height: 3px;
  background-color: currentColor;
  position: absolute;
 }
}
%pattern-bg {
 background-image:linear-gradient(140deg,
  $dark 0%, shade-color($dark, 15%) 25%,
  $dark 25%, shade-color($dark, 15%) 50%,
  $dark 50%, shade-color($dark, 15%) 75%,
  $dark 75%, shade-color($dark, 15%) 100%
 );
}
.bgPattern, body:not(.noBg) {@extend %pattern-bg}
.img-thumbnail {
 &:not(img) {
  --offsetTop: 20px;
  --offsetSide: 30px;
  @include media-breakpoint-up(lg) {
  --offsetTop: 2.5rem;
  --offsetSide: 3rem;
  }
  border-radius: 0;
  border: 0;
  display: inline-block;
  padding: var(--offsetTop) 0 0 var(--offsetSide);
  position: relative;
  &:before {
   @extend %pattern-bg;
   bottom: var(--offsetTop);
   content: '';
   left: 0;
   position: absolute;
   right: var(--offsetSide);
   top: 0;
   z-index: 0;
  }
  img {
   position: relative;
   z-index: 1;
  }
 }
}

.carousel {
 padding-bottom: 3rem;
}
.carousel-indicators {
 margin-bottom: 0;
 [data-bs-target] {
  width: 23px;
  height: 23px;
  opacity: 1;
  border-radius: 50%;
  margin-right: 5px;
  margin-left: 5px;
  background-color: #EBEBEB;
  border: 1px solid transparent!important;
 }
 .active {
  background-color: $primary;
 }
}

#testimonals {
 position: relative;
 &:before {
  content: '';
  position: absolute;
  height: 14rem;
  background-color: $white;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
 }
}
#main {
 @include media-breakpoint-down(md) {
  border-top: 2px solid $dark;
 }
}
#pageContent, #sidePanel {
 padding: 1rem;
}
#pageContent {
 color: $gray-800;
 > {
  h1, h2, h3 {margin-bottom: $spacer}
 }
 a {
  color: $primary;
  text-decoration: underline;
  &:hover {
   color: complement($primary)
  }
 }
}
#sidePanel {
 color: $gray-500;
 background-color: rgba($primary, .16);
 hr {
  background-color: shade-color($primary, 15%);
 }
 .btn-light {
  &:hover {
   background-color: $primary;
   border-color: $primary;
   color: color-contrast($primary);
  }
 }
 @include media-breakpoint-up(md) {
  flex: 0 0 30%;
  max-width: 30%;
 }
 @include media-breakpoint-up(xl) {
  flex: 0 0 25%;
  max-width: 25%;
 }
}