$min-contrast-ratio: 3;

// Typography
$font-weight-lighter: 300;
$font-weight-light: 400;
$font-weight-normal: 500;
$font-weight-bold: 600;
$font-weight-bolder: 700;
$font-family-sans-serif: 'Quicksand';
$headings-font-family: 'Nunito';
$headings-font-weight: 500;

$h1-font-size: 2.25rem;

$text-muted: $gray-500;

$border-radius-pill: 50rem;

$box-shadow: 0 0 1rem rgba($black, .3);

$btn-border-radius: $border-radius-pill;
$btn-border-radius-sm: $btn-border-radius;
$btn-border-radius-lg: $btn-border-radius;

$container-max-widths: (
  lg: 1000px,
  xl: 1200px,
  xxl: 1400px
);

$input-border-color: transparent;
$input-border-radius: $border-radius-pill;
$input-border-radius-sm: $input-border-radius;
$input-border-radius-lg: $input-border-radius;

$link-decoration: none;